





























































































































import "weui";
import Vue from "vue";
import { Toast, Dialog, List } from "vant";

import AMapLoader from "@amap/amap-jsapi-loader";
import "@amap/amap-jsapi-types";
declare const AMap: AMap.NameSpace;

interface Battery {
  a: number; // lat
  n: number; // lng
  p: number; // priority
  i: number; // batteryId
  o: boolean; // isOnline
  stm: boolean; // showTaskManager
  phone: string;
  gbem: string;
  task: boolean;
  batteryId: number;
  roomId: number;
  batteryNo: string;
  userName: string;
  updateTime: string;
  batV: string;
  soc: string;
  eq: string;
  ebikeId: string;

  show?: boolean;

  // AMap.MassData
  lnglat: AMap.LngLat;
  style?: number;
}

enum MarkStyle {
  Nothing,
  OnlineBattery,
  OfflineBattery,
  ErrorBattery,
  TaskBattery,
  ShowBattery
}

export default Vue.extend({
  components: {
    [List.name]: List
  },
  data() {
    return {
      map: {} as AMap.Map,
      marker: {} as AMap.Marker,
      mass: {} as AMap.MassMarks,
      geolocation: {} as AMap.Geolocation,

      list: [] as Battery[],
      listData: {
        loading: false,
        finished: false
      },
      infoList: [],
      refresher: null,
      page: 0,
      batteryInfo: {} as Battery,
      mapSearch: "",
      searchForm: {
        isRent: this.$route.query.isRent || "",
        isOnline: this.$route.query.isOnline || "",
        inType: this.$route.query.inType,
        deviceType: this.$route.query.deviceType || 0,
        isShow: this.$route.query.isShow || 0
      },
      states: {
        showList: false,
        showFilter: false,
        showStat: false,
        showStatCount: false,
        showSearch: false
      },
      stat: {
        countsM: 0,
        rectangle: {} as AMap.Rectangle,
        firstPoint: {} as AMap.Marker,
        isOnFirstStep: true,
        southWestLat: 0,
        southWestLng: 0,
        northEastLng: 0,
        northEastLat: 0
      }
    };
  },
  watch: {
    "states.showList": "onListShowHide",
    "states.showSearch": "onSearchShowHide",
    "states.showStat": "onStatShowHide"
  },
  mounted() {
    const loading = Toast.loading("正在加载地图...");
    AMapLoader.load({
      key: process.env.VUE_APP_AMAP_KEY,
      version: process.env.VUE_APP_AMAP_VERSION,
      plugins: ["AMap.Geolocation"]
    })
      .then(amapLoader => {
        loading.clear();
        this.map = new amapLoader.Map("map", {
          resizeEnable: true,
          zoom: 12,
          rotateEnable: false
        });

        // 移动到上一次的位置
        const lastLng = localStorage.getItem("nowlng");
        const lastLat = localStorage.getItem("nowlat");
        if (lastLng && lastLat) {
          this.map.panTo([Number(lastLng), Number(lastLat)]);
        }

        // 设置拖拽结束保存位置
        this.map.on("dragend", () => {
          this.batteryInfo.batteryId = 0;
          const center = this.map.getCenter();
          localStorage.setItem("nowlng", center.lng + "");
          localStorage.setItem("nowlat", center.lat + "");
        });

        // 获取数据
        this.getData();
      })
      .catch(() => {
        Toast.fail("地图加载失败");
      });
  },
  methods: {
    /**
     * 搜索
     */
    onSearchShowHide(show: boolean) {
      if (!show) return;
      this.$nextTick(() => {
        const loading = Toast.loading("正在加载地图插件");
        AMap.plugin(["AMap.PlaceSearch", "AMap.Autocomplete"], () => {
          loading.clear();
          const auto = new AMap.Autocomplete({
            input: "pre-search-input"
          });
          const placeSearch = new AMap.PlaceSearch({
            map: this.map
          });
          auto.on("select", (e: { poi: { name: string; adcode: number }; type: string }) => {
            this.states.showSearch = false;
            placeSearch.search(e.poi.name);
          });
        });
      });
    },
    /**
     * 筛选
     */
    filterBattery() {
      this.states.showFilter = false;
      this.mass.clear();
      this.getData();
    },
    /**
     * 定位
     */
    moveToCurrentLocation() {
      // 加载地图定位
      AMap.plugin(["AMap.Geolocation"], () => {
        if (this.$isEmptyObject(this.geolocation)) {
          const geolocation = new AMap.Geolocation({
            enableHighAccuracy: true,
            timeout: 10000, // ms
            showButton: false
          });
          this.map.addControl(geolocation);
          this.geolocation = geolocation;
        }
        this.geolocation.getCurrentPosition((status: string, result: { info: string; message: string; position: { lng: string; lat: string } }) => {
          if (status == "complete") {
            localStorage.setItem("nowlng", result.position.lng);
            localStorage.setItem("nowlat", result.position.lat);
          } else {
            Toast.fail("定位失败_" + result.info + "," + result.message);
          }
        });
      });
    },
    /**
     * 跳转任务列表
     */
    goTaskList(batteryNo: string) {
      this.$toMiniAppWeb("/web/manager/task_list?deviceId=" + batteryNo);
    },
    /**
     * 取电池
     */
    takeBattery(batteryId: number, batteryNo: string) {
      Dialog.confirm({
        title: "确认操作",
        message: "是否解锁电池？"
      }).then(() => {
        this.$axios
          .post("/api/manage/takeBattery", {
            qrCode: batteryNo,
            batteryId: batteryId
          })
          .then(() => {
            Toast.success("解锁成功");
          });
      });
    },
    /**
     * 跳转电池详情
     */
    goBatteryDetail(batteryId: number, roomId: number) {
      if (!batteryId) {
        Toast.fail("异常");
        return;
      }
      if (roomId == 0) {
        localStorage.setItem("inputType", "3");
        localStorage.setItem("inputValue", batteryId + "");
        localStorage.setItem("searchType", "1");
        this.$toMiniAppWeb("/web/manager/device_detail.html");
      } else {
        this.$toMiniAppWeb("/web/manager/manager_switch_cabinet_information?roomId=" + roomId);
      }
    },
    /**
     * 获取地图数据
     */
    getData() {
      this.$axios.post("/api/manage/getDeviceBatteryMap", this.searchForm).then(res => {
        const e = res.data;
        const list: Battery[] = e.data.list;
        for (const n of list) {
          n.lnglat = new AMap.LngLat(n.n, n.a);
          if (n.p != 0) {
            n.style = MarkStyle.TaskBattery;
          } else if (n.o) {
            n.style = MarkStyle.OnlineBattery;
          } else if (!n.o) {
            n.style = MarkStyle.OfflineBattery;
          } else {
            n.style = MarkStyle.Nothing;
          }
        }
        this.list = list;
        this.mass = new AMap.MassMarks(list, {
          zIndex: 111,
          cursor: "pointer",
          style: this.getStyles()
        });
        this.mass.setMap(this.map);
        const marker = new AMap.Marker({ content: " ", map: this.map });
        this.mass.on("click", e => {
          marker.setPosition(e.data.lnglat);
          marker.setLabel({ content: "当前", offset: new AMap.Pixel(0, -25), direction: "top" });
          this.getBatteryInfo(e.data.i);
        });
        this.marker = marker;
      });
    },
    /**
     * 获取单个电池数据
     */
    getBatteryInfo(batteryId: number) {
      this.$axios.post("/api/manage/getDeviceBatteryMapList", { batteryIds: batteryId }).then(res => {
        const e = res.data;
        if (e.data.list.length !== 1) {
          Toast.fail("返回数据异常");
          return;
        }
        this.batteryInfo = e.data.list[0];
      });
    },
    /**
     * 列表
     */
    onListShowHide(show: boolean) {
      if (show && this.infoList.length === 0) {
        if (this.list.length === 0) {
          Toast.fail("请先等待加载完毕");
        } else {
          this.getList(); // 触发第一次
        }
      }
    },
    getList() {
      const size = 20;
      const start = this.page * size;
      const end = Math.min((this.page + 1) * size, this.list.length);
      const isEnd = end === this.list.length;
      const rawList: Battery[] = this.list.slice(start, end);
      const idList: number[] = [];
      for (const n of rawList) {
        idList.push(n.i);
      }
      const ids = idList.join(",");
      if (!ids) {
        Toast.fail("错误");
        return;
      }
      this.listData.loading = true;
      this.$axios.post("/api/manage/getDeviceBatteryMapList", { batteryIds: ids }).then(res => {
        this.page++;
        this.listData.loading = false;
        const e = res.data;
        this.infoList = this.infoList.concat(e.data.list);
        if (isEnd) {
          this.listData.finished = true;
        }
      });
    },
    /**
     * 统计
     */
    onStatShowHide(show: boolean) {
      if (show) {
        this.map.on("click", this.recordPoint);
      } else {
        this.stat.countsM = 0;
        this.map.off("click", this.recordPoint);
        this.map.remove(this.stat.firstPoint);
        // if (this.stat.firstPoint.remove) {
        //   this.stat.firstPoint.remove();
        // }
        this.map.remove(this.stat.rectangle);
        // if (this.stat.rectangle.remove) {
        //   this.stat.rectangle.remove();
        // }
        this.stat.isOnFirstStep = true;
        this.states.showStatCount = false;
      }
    },
    recordPoint(e: { lnglat: AMap.LngLat }) {
      if (this.stat.isOnFirstStep) {
        this.stat.southWestLng = e.lnglat.getLng();
        this.stat.southWestLat = e.lnglat.getLat();
        const icon = new AMap.Icon({
          imageSize: new AMap.Size(30, 30),
          image: "/images/location.png"
        });
        this.stat.firstPoint = new AMap.Marker({
          icon: icon,
          position: [this.stat.southWestLng, this.stat.southWestLat],
          offset: new AMap.Pixel(-13, -30)
        });
        this.stat.firstPoint.setMap(this.map);
        this.map.remove(this.stat.rectangle);
        // if (this.stat.rectangle.remove) {
        //   this.stat.rectangle.remove();
        // }
        this.stat.isOnFirstStep = false;
      } else {
        this.stat.northEastLng = e.lnglat.getLng();
        this.stat.northEastLat = e.lnglat.getLat();
        this.setRectangle();
        this.stat.southWestLat = 0;
        this.map.remove(this.stat.firstPoint);
        // this.stat.firstPoint.remove();
        this.stat.isOnFirstStep = true;
      }
    },
    setRectangle() {
      const x1 = Math.min(this.stat.southWestLng, this.stat.northEastLng);
      const y1 = Math.min(this.stat.southWestLat, this.stat.northEastLat);
      const x2 = Math.max(this.stat.southWestLng, this.stat.northEastLng);
      const y2 = Math.max(this.stat.southWestLat, this.stat.northEastLat);

      const southWest = new AMap.LngLat(x1, y1);
      const northEast = new AMap.LngLat(x2, y2);
      const bounds = new AMap.Bounds(southWest, northEast);
      this.stat.rectangle = new AMap.Rectangle({
        bounds: bounds,
        strokeColor: "red",
        strokeWeight: 6,
        strokeOpacity: 0.5,
        strokeDasharray: [30, 10],
        strokeStyle: "dashed",
        fillColor: "blue",
        fillOpacity: 0.2,
        cursor: "pointer",
        zIndex: 50
      });
      this.stat.rectangle.setMap(this.map);
      let countsM = 0;
      for (const n of this.list) {
        if (x1 < n.n && n.n < x2 && y1 < n.a && n.a < y2) {
          countsM++;
        }
      }
      this.stat.countsM = countsM;
      this.states.showStatCount = true;
    },
    /**
     * 点样式
     */
    getStyles(): AMap.MassMarkersStyleOptions[] {
      return [
        {
          url: "",
          anchor: new AMap.Pixel(0, 0),
          size: new AMap.Size(0, 0),
          rotation: 0
        },
        {
          url: "/images/online_battery.png", //在线绿色图标
          anchor: new AMap.Pixel(15, 30),
          size: new AMap.Size(30, 30),
          rotation: 0
        },
        {
          url: "/images/offline_battery.png", //离线灰色图标
          anchor: new AMap.Pixel(15, 30),
          size: new AMap.Size(30, 30),
          rotation: 0
        },
        {
          url: "/images/error_battery.png", //异常红色图标
          anchor: new AMap.Pixel(15, 30),
          size: new AMap.Size(30, 30),
          rotation: 0
        },
        {
          url: "/images/task_battery.png", //有任务黄色图标
          anchor: new AMap.Pixel(15, 30),
          size: new AMap.Size(30, 30),
          rotation: 0
        },
        {
          url: "/images/show_battery.png", //有显示黄色图标
          anchor: new AMap.Pixel(15, 30),
          size: new AMap.Size(30, 30),
          rotation: 0
        }
      ];
    }
  }
});
